import { HomePage } from "../pages";

const routes = [
  {
    path: "/",
    component: <HomePage />,
  },
];

export default routes;
